export const simpleSearch = (request, searchInput) => {
  const lowercasedInput = searchInput?.toLowerCase().replace(/\s/g, "");
  const host = request?.request?.host?.toLowerCase() || "";
  const method = request?.request?.requestData?.method?.toLowerCase() || "";
  const status = request?.response?.responseMetadata?.status?.toString() || "";
  const pathAndQuery = request?.request?.pathAndQuery?.toLowerCase() || "";

  return (
    host?.toLowerCase()?.includes(lowercasedInput) ||
    method?.toLowerCase()?.includes(lowercasedInput) ||
    status?.toLowerCase()?.includes(lowercasedInput) ||
    pathAndQuery?.toLowerCase()?.includes(lowercasedInput)
  );
};
