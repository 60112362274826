const fetchSingleImage = async (icon) => {
  const token = localStorage.getItem("authorization");
  if (icon?.uri) {
    try {
      const response = await fetch(icon?.uri, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response?.status}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return url;
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }
};

export default fetchSingleImage;
