import { simpleSearch } from "./simpleSearch";
export const matchCondition = (request, condition) => {
  const { key, operator, value } = condition;
  if (!request?.request && !request?.response) return false;

  let fieldValue = null;

  if (key && !operator && !value) {
    return simpleSearch(request, key);
  }

  if (key === "status") {
    fieldValue = request?.response?.responseMetadata?.status;
  } else if (key === "method") {
    fieldValue = request?.request?.requestData?.method;
  } else if (key === "host") {
    fieldValue = request?.request?.host;
  } else if (key === "path") {
    fieldValue = request?.request?.pathAndQuery;
  }

  if (fieldValue === null || fieldValue === undefined) return false;

  // Convert fieldValue to string if it is not a string
  fieldValue = fieldValue.toString();

  switch (operator) {
    case "==":
      return fieldValue === value;
    case "!=":
      return fieldValue !== value;
    case ">":
      return parseFloat(fieldValue) > parseFloat(value);
    case ">=":
      return parseFloat(fieldValue) >= parseFloat(value);
    case "<":
      return parseFloat(fieldValue) < parseFloat(value);
    case "<=":
      return parseFloat(fieldValue) <= parseFloat(value);
    default:
      return false;
  }
};
