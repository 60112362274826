import xml2js from "minexml2js";
import transformForXml from "./helpers/transformForXml";

export function transformXml(skeleton, addToast) {
  try {
    const builder = new xml2js.Builder();
    const xml = builder.buildObject(transformForXml(skeleton));
    const finalXML = xml
      ?.replace(new RegExp("&amp;", "g"), "&")    
      .replace(new RegExp("&lt;", "g"), "<")  
      .replace(new RegExp("&gt;", "g"), ">");
    return finalXML;
  } catch (error) {
    addToast(`Error in transformXml: ${error}`, "error");
    console.error("Error in transformXml:", error);
    return null;
  }
}
