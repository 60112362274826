const importRequestsPairs = (file) => {
  //if file not type json alert
  if (file.type !== "application/json") {
    alert("Please select a JSON file");
    return;
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const importedData = JSON.parse(event.target.result);

        //checking if imported data have request and response in it s objects
        if (importedData?.some((data) => data?.request && data?.response)) {
          resolve(importedData);
        } else {
          alert("Invalid file format - missing request or response data");
          reject("Invalid file format");
        }
      } catch (error) {
        alert("Invalid file format - missing request or response data");
        reject(error); // Reject the promise in case of error
      }
    };

    reader.onerror = (error) => {
      reject(error); // Reject the promise in case of read error
    };

    // Read the file as a text
    reader.readAsText(file);
  });
};

export default importRequestsPairs;
