const exportRequestsPairs = (requestsData, fileName = "requests-data.json") => {
  const updatedRequestsData = requestsData?.map((request) => {
    if (request?.request) {
      return {
        ...request,
        request: {
          ...request.request,
          exported: true, // Add the exported flag
        },
      };
    }
  });

  const jsonData = JSON.stringify(updatedRequestsData, null, 2);

  // Create a blob with the JSON data
  const blob = new Blob([jsonData], { type: "application/json" });

  // Create an anchor element and set the href to the blob URL
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;

  // Append the anchor to the body, click it to trigger the download, and then remove it
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Release the object URL
  URL.revokeObjectURL(url);
};

export default exportRequestsPairs;
