import React, { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { handleComponentProp } from "../../services/handleComponentProperties";
import "./index.scss";
import { getLabelByLanguage } from "../../../../../../../utils/getLabelByLanguage";
import useRandomData from "./useRandomData";
import { isSelectedComponent } from "../../services/isSelectedComponent";

function Attribute({
  profile = false,
  index = null,
  data = {},
  path = [],
  readOnly = true,
  numAttributes = 0,
  currentPath = [],
  classification = false,
  fromSection = false,
  sectionDisplay = false,
  setHoverAtt = () => {},
  isPathAtt = false,
}) {
  let store = useStore();
  let edit = useSelector((state) =>
    state?.entities?.editor ? state.entities.editor : {}
  );

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );
  const randomData = useRandomData(data.type);
  const randomPathData = useRandomData(data?.type?.toUpperCase());
  const render = useMemo(
    () => (
      <React.Fragment>
        {data?.hidden?.toLowerCase() === "true" ? (
          !readOnly && (
            <div
              className={`attribute-container withoutLabel ${
                isSelectedComponent(
                  readOnly,
                  index === null
                    ? [...path, "attribute"]
                    : [...path, "attribute", index],
                  currentPath
                ) && "selectedComponent"
              }`}
              onClick={(e) =>
                handleComponentProp(
                  e,
                  index?.toString(),
                  data,
                  path,
                  store,
                  "attribute"
                )
              }
              style={{
                padding: (fromSection || !readOnly) && "3px",
                justifyContent: fromSection && !sectionDisplay && "flex-start",
                gap: fromSection && "4px",
                flexDirection:
                  fromSection && !sectionDisplay
                    ? "row"
                    : language?.toLowerCase() === "ar"
                    ? "row-reverse"
                    : "column",
                alignItems:
                  fromSection && !sectionDisplay
                    ? "flex-end"
                    : language?.toLowerCase() === "ar"
                    ? "flex-end"
                    : "flex-start",
              }}
              onMouseEnter={() => setHoverAtt(true)}
              onMouseLeave={() => setHoverAtt(false)}
            >
              {data?.displaylabel?.toLowerCase() !== "false" && (
                <div className="att-label">
                  <div className="hidden-label">{"[Hidden]"}</div>
                  {data?.label
                    ? `${getLabelByLanguage(language, data)}:`
                    : "Add Label"}
                </div>
              )}
              <div className="att-value withoutLabel">
                {data?.displaylabel?.toLowerCase() === "false" && (
                  <div className="hidden-label">{"[Hidden]"}</div>
                )}
                {data?.type ? randomData : "--"}
              </div>
            </div>
          )
        ) : (
          <div
            className={`attribute-container ${
              isSelectedComponent(
                readOnly,
                index === null
                  ? [...path, "attribute"]
                  : [...path, "attribute", index?.toString()],
                currentPath
              ) && "selectedComponent"
            } ${!readOnly && "hovered"}`}
            onClick={(e) =>
              handleComponentProp(
                e,
                index?.toString(),
                data,
                path,
                store,
                "attribute"
              )
            }
            style={{
              padding: (fromSection || !readOnly) && "6px",
              justifyContent: fromSection && "flex-start",
              gap: fromSection && "4px",
              flexDirection:
                fromSection && !sectionDisplay
                  ? "column"
                  : language?.toLowerCase() === "ar"
                  ? "row-reverse"
                  : "row",
              alignItems:
                language?.toLowerCase() === "ar" &&
                fromSection &&
                !sectionDisplay
                  ? "flex-end"
                  : fromSection && !sectionDisplay && "flex-start",
            }}
            onMouseEnter={() => setHoverAtt(true)}
            onMouseLeave={() => setHoverAtt(false)}
          >
            {data?.displaylabel?.toLowerCase() !== "false" && (
              <div
                className="att-label"
                style={{
                  padding: isPathAtt && "0 6px",
                  color: isPathAtt && readOnly && "#aaa",
                }}
              >
                {language.toLowerCase() === "ar"
                  ? data?.label
                    ? `:${getLabelByLanguage(language, data)}`
                    : isPathAtt
                    ? data?.type
                      ? randomPathData
                      : "Config att"
                    : ":Add Label"
                  : data?.label
                  ? `${getLabelByLanguage(language, data)}:`
                  : isPathAtt
                  ? data?.type
                    ? randomData
                    : "Config att"
                  : "Add Label"}
              </div>
            )}
            {!isPathAtt && (
              <div className="att-value">{data?.type ? randomData : "--"}</div>
            )}
          </div>
        )}
      </React.Fragment>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, edit?.parentToEdit, randomData, language, sectionDisplay]
  );
  return render;
}

export default Attribute;
