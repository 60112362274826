import React, { useMemo, useEffect, useState } from "react";

import themeColors from "../../../../../theme/themeColors";
import RequestsHeader from "./RequestsHeader";
import getStatusOrMethodCodeColor from "../services/getStatusOrMethodCodeColor";
import Icon from "../../../../../components/CustomIcon/Icon";

const Requests = ({
  mode,
  setMode,
  setRequests,
  setInterceptorIsPaused,
  interceptorIsPaused,
  requestsData,
  setSelectedRequest,
  selectedRequest,
  startSocket,
  stopSocket,
  searchedRequestsPairs,
  setSearchedRequestsPairs,
  isSearching,
  setIsSearching,
}) => {
  const [selectedRequestsToExport, setSelectedRequestsToExport] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  // Updating selected request when requestsData changes
  useEffect(() => {
    if (selectedRequest && requestsData) {
      const updatedSelectedRequest = requestsData?.find(
        (request) =>
          request?.request?.requestData?.meta?.requestId ===
          selectedRequest?.request?.requestData?.meta?.requestId
      );
      if (updatedSelectedRequest) {
        setSelectedRequest(updatedSelectedRequest);
      } else {
        setSelectedRequest(null);
      }
    }
  }, [requestsData, selectedRequest, setSelectedRequest]);
  const renderRequests = useMemo(() => {
    const requestsToRender = isSearching ? searchedRequestsPairs : requestsData;

    return requestsToRender
      .toReversed()
      ?.filter((request) => request?.request)
      ?.map((request, index) => {
        const requestId = request?.request?.requestData?.meta?.requestId;

        const isSelected = selectedRequest
          ? selectedRequest?.request?.requestData?.meta?.requestId === requestId
          : false;

        const isExported = request?.request?.exported || false;
        const responseStatus = request?.response?.responseMetadata?.status;

        return (
          <div
            className="a-request"
            onClick={() => {
              request?.request?.requestData?.meta?.requestId
                ? setSelectedRequest(request)
                : setSelectedRequest(null);
            }}
            key={requestId}
            style={{
              cursor: "pointer",
              borderRadius: "2px",
              backgroundColor: isSelected
                ? themeColors.light.interceptorBgCl
                : index % 2 === 0
                ? "white"
                : "#f5f5f5",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: isSelected
                ? themeColors.light.selectedRequest
                : "#eee",
            }}
          >
            <div className="requests-display">
              <div
                className="border-of-request"
                style={{
                  padding: "5px",
                }}
              >
                <input
                  onClick={() => {
                    setSelectedRequestsToExport((prev) => {
                      if (prev?.includes(request)) {
                        setAllSelected(false);
                        return prev?.filter((req) => {
                          return (
                            req?.request?.requestData?.meta?.requestId !==
                            request?.request?.requestData?.meta?.requestId
                          );
                        });
                      } else {
                        return [...prev, request];
                      }
                    });
                  }}
                  checked={selectedRequestsToExport?.includes(request)}
                  type="checkbox"
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  color: getStatusOrMethodCodeColor(
                    request?.request?.requestData?.method?.toUpperCase(),
                    "method"
                  ),
                  fontWeight: "400",
                  fontSize: "16px",
                }}
                className={`padding-of-request ${
                  !isSelected ? "border-of-request" : ""
                }`}
              >
                {request?.request?.requestData?.method}
              </div>

              <div
                className={`request-host-path padding-of-request ${
                  !isSelected ? "border-of-request" : ""
                }`}
              >
                {request?.request?.host}
              </div>
              <div
                className={`request-host-path padding-of-request ${
                  !isSelected ? "border-of-request" : ""
                }`}
              >
                {request?.request?.pathAndQuery}
              </div>
              <div
                style={{
                  color: getStatusOrMethodCodeColor(responseStatus),
                  fontWeight: "500",
                  fontSize: "16px",
                  padding: "5px",
                }}
              >
                {responseStatus
                  ? responseStatus
                    ? responseStatus === "blocked"
                      ? "blocked"
                      : responseStatus
                    : "pending"
                  : "pending"}
              </div>
            </div>
          </div>
        );
      });
  }, [
    requestsData,
    searchedRequestsPairs,

    selectedRequestsToExport,
    isSearching,
    // responsesData,
    // setupSocketListener,

    selectedRequest,
    setSelectedRequest,
    // getResponseOfrequest,
    // socket,
  ]);

  return (
    <div
      className="pane "
      style={{
        scrollBehavior: "smooth",
        position: "relative",
        //no overflow x
        overflowX: "hidden",
      }}
    >
      {/* <div className="utility-bar">
        <div className="restBar">Imported Requests</div>
        <div className="expend-icon">
          <Icon icon={"reduire"} size={16} />
        </div>
        <div className="maximize-icon">
          <Icon icon={false === "" ? "minimize" : "maximize"} size={18} />
        </div>
      </div> */}
      <RequestsHeader
        selectedRequestsToExport={selectedRequestsToExport}
        mode={mode}
        searchedRequestsPairs={searchedRequestsPairs}
        setSearchedRequestsPairs={setSearchedRequestsPairs}
        isSearching={isSearching}
        setIsSearching={setIsSearching}
        setMode={setMode}
        setRequests={setRequests}
        startSocket={startSocket}
        stopSocket={stopSocket}
        setInterceptorIsPaused={setInterceptorIsPaused}
        interceptorIsPaused={interceptorIsPaused}
        requestsData={requestsData}
        setSelectedRequest={setSelectedRequest}
      />

      {requestsData?.length > 0 ? (
        <div className="requests-table-container">
          {/* Table Header */}
          <div className="requests-table-heaeder">
            {/* //checkbox */}
            <div className="table-keys" style={{ padding: "5px" }}>
              <input
                // selectall

                onClick={() => {
                  setAllSelected(!allSelected);

                  if (allSelected) {
                    setSelectedRequestsToExport([]);
                  } else {
                    setSelectedRequestsToExport(requestsData);
                  }
                }}
                checked={
                  selectedRequestsToExport?.length === requestsData?.length
                }
                type="checkbox"
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
            <div className="table-keys">Method</div>
            <div className="table-keys">Host</div>
            <div className="table-keys">Path and query</div>
            <div className="table-keys">Status</div>
          </div>

          {/* Table Body */}
          {renderRequests}
          {/* <div
            className="utility-bar"
            style={{
              position: "sticky",
              bottom: "5px",
            }}
          >
            <div className="restBar">Imported Requests</div>
            <div className="expend-icon">
              <Icon icon={"arrow-up"} size={16} />
            </div>
            <div className="maximize-icon">
              <Icon icon={true === "" ? "minimize" : "maximize"} size={18} />
            </div>
          </div> */}
        </div>
      ) : (
        <h6>Waiting for requests...</h6>
      )}
    </div>
  );
};

export default Requests;
