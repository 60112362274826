import React, { useState, useEffect } from "react";
import Icon from "../../../../components/CustomIcon/Icon";
import themeColors from "../../../../theme/themeColors";
import CustomButton from "../../../../components/CustomButton";
import Lottie from "react-lottie";
import { LoadingOptions } from "../Dashboard/data";
import CustomModal from "../../../../components/CustomModal";

import { changeMode, getMode } from "./services/getMode";
import importRequestsPairs from "./requests-area/export-import/importRequets";

const Modes = {
  disableInterceptor: { disableInterceptor: true },
  rabbitmq: { rabbitmq: true },
  justSocket: { justSocket: true },
};
const NoRequests = ({
  mode,
  setMode,
  startSocket,
  stopSocket,
  setRequests,
  requestsLength,
  interceptorIsPaused,
  setInterceptorIsPaused,
}) => {
  const [isChanginMode, setIsChangingMode] = useState(false);
  const [confirmChangeMode, setConfirmChangeMode] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);
  const [isLoadingMode, setIsLoadingMode] = useState(false);
  const importRequests = async (file) => {
    try {
      const result = await importRequestsPairs(file);
      if (result) {
        setRequests(result);
      }
    } catch (error) {}
  };
  const handleModeChange = async (modeToChange) => {
    setIsChangingMode(false);
    setIsLoadingMode(true);
    const mode = await changeMode(modeToChange);
    setMode(mode);
    setIsLoadingMode(false);
  };
  return (
    <div
      className="no-requests"
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <CustomModal
        open={confirmChangeMode}
        onClose={() => setConfirmChangeMode(false)}
        onClickPrimaryAction={() => {
          handleModeChange(selectedMode);
          setConfirmChangeMode(false);
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => setConfirmChangeMode(false)}
        primaryLabel="Change Mode"
        secondaryLabe="Cancel"
        modalTitle="Change Interceptor Mode"
        alignment="center"
      >
        <p>You’re about to switch Mode. Would you like to proceed?</p>
      </CustomModal>
      <div
        style={{
          display: "flex",
          padding: "10px",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h2
          style={{
            color: themeColors.light.requestsNb,
            fontSize: "24px",
          }}
        >
          {requestsLength}{" "}
          <span
            style={{
              fontSize: "20px",
              fontWeight: 400,
            }}
          >
            Requests
          </span>
        </h2>

        <div style={{ display: "flex", gap: "4px" }}>
          <CustomButton
            iconName={interceptorIsPaused ? "Resume" : "Pause"}
            iconColor={themeColors.light.requestsNb}
            iconSize={30}
            onClick={() => {
              interceptorIsPaused ? startSocket() : stopSocket();
              setInterceptorIsPaused(!interceptorIsPaused);
            }}
            tooltip={
              interceptorIsPaused ? "Resume intercepting" : "Pause intercepting"
            }
          />

          {/* vertical seperator */}
          <div
            style={{
              borderLeft: "2px solid #ccc",
              height: "30px",
              margin: "0 10px",
            }}
          ></div>
          <CustomButton
            iconName="export2"
            iconColor="#4C36A5"
            iconSize={30}
            tooltip="Import Requests"
            onClick={() => {
              const input = document.createElement("input");
              input.type = "file";
              input.accept = ".json";
              input.onchange = (e) => {
                if (e.target.files && e.target.files[0]) {
                  importRequests(e.target.files[0]);
                }
              };
              input.click();
            }}
          />
          <div
            style={{
              borderLeft: "2px solid #ccc",
              height: "30px",
              margin: "0 10px",
            }}
          ></div>
          <Icon icon="delete" color="#B9B8BD" size={30} />
          <div
            style={{
              borderLeft: "2px solid #ccc",
              height: "30px",
              margin: "0 10px",
            }}
          ></div>
          {/* mode */}
          {/* it s select but  have first value as mode?.justSocket */}
          {isLoadingMode ? (
            <Lottie options={LoadingOptions} height={40} width={40} />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                position: "relative",
              }}
            >
              {isChanginMode && (
                <div
                  style={{
                    position: "absolute",
                    top: "35px",
                    right: "0",
                    backgroundColor: "#fff",
                    border: "1px solid #EEEEEE",
                    borderRadius: "2px",
                    zIndex: 9999,
                    display: "flex",

                    flexDirection: "column",
                  }}
                >
                  <div
                    className="selection-mode"
                    onClick={() => {
                      setSelectedMode(Modes.justSocket);
                      setConfirmChangeMode(true);
                      setIsChangingMode(false);
                    }}
                  >
                    <div>Realtime</div>
                    {/* checked icon for if it s selected */}
                    {mode?.httpInterceptor ? (
                      !mode?.rabbitmq && mode?.justSocket ? (
                        <Icon
                          icon="check"
                          size={20}
                          style={{
                            color: "#16A34A",
                          }}
                        />
                      ) : null
                    ) : null}
                  </div>
                  <div
                    className="selection-mode"
                    onClick={() => {
                      setSelectedMode(Modes.rabbitmq);
                      setConfirmChangeMode(true);
                      setIsChangingMode(false);
                    }}
                  >
                    <div>Persist</div>
                    {/* checked icon for if it s selected */}
                    {mode?.httpInterceptor ? (
                      mode?.rabbitmq ? (
                        <Icon
                          icon="check"
                          size={20}
                          style={{
                            color: "#16A34A",
                          }}
                        />
                      ) : null
                    ) : null}
                  </div>

                  <div
                    className="selection-mode"
                    onClick={() => {
                      setSelectedMode(Modes.disableInterceptor);
                      setConfirmChangeMode(true);
                      setIsChangingMode(false);
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Disabled
                    </div>
                    {/* checked icon for if it s selected */}
                    {!mode?.httpInterceptor ? (
                      <Icon
                        icon="check"
                        size={20}
                        style={{
                          color: "#16A34A",
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              )}
              <div
                style={{
                  //disabled border red
                  border: !mode?.httpInterceptor
                    ? "1px solid #FF0000"
                    : "1px solid #16A34A",
                  color: !mode?.httpInterceptor ? "#FF0000" : "#16A34A",
                  backgroundColor: !mode?.httpInterceptor
                    ? "#FFD6D8"
                    : "#DCF1E4",
                  borderRadius: "72px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {mode?.httpInterceptor ? "On" : "Off"}
              </div>
              {/* mode */}

              <div
                style={{
                  color: "#39354A",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                {mode?.httpInterceptor
                  ? mode?.rabbitmq
                    ? "Persist"
                    : "Realtime"
                  : "Disabled"}
              </div>
              {/* menu-dots-vertical */}
              <CustomButton
                onClick={() => {
                  setIsChangingMode(!isChanginMode);
                }}
                iconName="menu-dots-vertical"
                iconColor="#4C36A5"
                iconSize={20}
                tooltip="Change Mode"
              />
              {/* the actuial mode */}
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "5px",
          alignItems: "center",
          height: "100%",
        }}
      >
        {interceptorIsPaused ? (
          <Icon icon="Pause" size={150} style={{ color: "#B9B8BD" }} />
        ) : (
          <Icon icon="noData" size={150} style={{ color: "#B9B8BD" }} />
        )}
        <h2
          className="text-no-requests-or-paused"
          style={{
            color: "#B9B8BD",
            fontSize: "20px",
            textAlign: "center",
            fontWeight: 400,
          }}
        >
          {interceptorIsPaused ? (
            <span className="paused">
              Interception is paused.
              <br /> Resume to collect intercepted Requests.
            </span>
          ) : (
            <span className="waiting">
              Intercepted requests will appear here.
            </span>
          )}
        </h2>
      </div>
    </div>
  );
};

export default NoRequests;
