import React, { useEffect, useState } from "react";
import "./index.scss";
import SidebarBtn from "../SidebarBtn";
import Icon from "../../../../../../components/CustomIcon/Icon";
import fetchSingleImage from "../services/fetchSingleImage"; // Ensure this function is correctly implemented.

function ManySideBar({
  iconsListing = [],
  iconsSelected = [],
  setIconsListing = () => {},
  setIconsSelected = () => {},
  onDeleteIcon,
}) {
  const [imageURIs, setImageURIs] = useState({}); // Store image URIs

  const fetchImageURIs = async () => {
    const uris = {};
    for (const selectedIcon of iconsSelected) {
      // Fetch the image URI and store it
      const uri = await fetchSingleImage(selectedIcon);
      uris[selectedIcon.iconName] = uri; // Assuming iconName is unique
    }
    setImageURIs(uris); // Update state with fetched URIs
  };

  useEffect(() => {
    if (iconsSelected.length) {
      fetchImageURIs(); // Fetch URIs when icons are selected
    }
  }, [iconsSelected]);

  const deselectIcon = (iconToDeselect) => {
    setIconsSelected(iconsSelected.filter((icon) => icon !== iconToDeselect));
  };

  return (
    <div className="icons-sidebar">
      <div className="many-details">
        <div className="selected-icons">
          {iconsSelected.length !== iconsListing.length
            ? `${iconsSelected.length} Icons Selected`
            : `All Icons Selected (${iconsSelected.length})`}
        </div>

        <div className="icons-display">
          {iconsSelected.map((selectedIcon, index) => (
            <div key={index} className="icon-display">
              <div className="display-dtl">
                <div className="small-icon">
                  <img
                    src={imageURIs[selectedIcon.iconName]} // Use fetched URI
                    alt={selectedIcon.iconName}
                  />
                </div>
                <div className="icon-name">{selectedIcon.iconName}</div>
              </div>
              <div className="delete-cross">
                <Icon
                  size={20}
                  icon="Close"
                  onClick={() => deselectIcon(selectedIcon)}
                />
              </div>
            </div>
          ))}
        </div>
        <SidebarBtn
          iconsSelected={iconsSelected}
          iconsListing={iconsListing}
          setIconsListing={setIconsListing}
          setIconsSelected={setIconsSelected}
          onDeleteIcon={onDeleteIcon}
        />
      </div>
    </div>
  );
}

export default ManySideBar;
