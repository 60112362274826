import React, { useState, useEffect, useRef } from "react";
import { useStore, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useTheme } from "../../theme/themeContext";
import { useLocation } from "react-router-dom";
import { UpdateMaximoLoginStatus } from "../../views/Installer/services/stepChange/maximoLoginStatus";
import { getServerConfig } from "../../views/Installer/services";
import handleClickedItem from "../../views/Designer/services/helpers/handleClickedItem";
import fetchListVersion from "../../views/Designer/services/revision";
import Switcher from "../Switcher";
import CustomButton from "../CustomButton";
import getButtonProps from "./services/getButtonProps";
import Modal from "../CustomModal";
import Icon from "../CustomIcon/Icon";
import "./index.scss";
import {
  Logout,
  handleUpdateMaintenance,
  rebootServer,
  updateMaintenance,
} from "./services";

const NavBar = () => {
  const navigate = useNavigate();
  const store = useStore();
  const { addToast } = useToasts();
  const [pageName, setPageName] = useState("");
  const [navigateTo, setNavigateTo] = useState();
  const [maintenanceStatus, setMaintenanceStatus] = useState();
  const [openRebootModal, setOpenRebootModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const { toggleTheme, themeMode, themeColors } = useTheme();
  const location = useLocation();
  const dropdownRef = useRef(null);

  let draftPresence = useSelector(
    (state) => state?.entities?.explorer?.draftDisplay || false
  );

  const isLoggedin = useSelector(
    (state) => state?.entities?.dashboard?.loggedIn
  );

  let versionsList = useSelector((state) =>
    state?.entities?.revision?.list ? state?.entities?.revision.list : []
  );

  const isTransactionServer = useSelector(
    (state) => state?.entities?.dashboard?.isTransactionServer || false
  );

  const completedInstallation = useSelector(
    (state) => state?.entities?.installer?.completedInstallation
  );

  useEffect(() => {
    const PagesMap = {
      "/monitor": "Monitor - Dashboard",
      "/installer": "Installer",
      "/appbuilder": "App Builder",
      "/assets": "Graphics Manager",
      "/support": "",
      "/*": "",
    };

    setPageName(PagesMap[location.pathname] || "");
  }, [location?.pathname]);

  useEffect(() => {
    (async () => {
      const data = await updateMaintenance();
      if (data && data?.success) {
        setMaintenanceStatus(data?.content?.ENABLE_MAINTENANCE);
      }
    })();
  }, []);

  useEffect(() => {
    const fetchServerConfig = async () => {
      try {
        const response = await getServerConfig();
        const isTransactionServer =
          response?.content?.status === "success" &&
          response?.content?.result?.isTransactionServer
            ? response?.content?.result?.isTransactionServer
            : false;

        store.dispatch({
          type: "dashboard/setIsTransactionServer",
          payload: {
            isTransactionServer,
          },
        });
      } catch (error) {
        console.error("fetchServerConfig=====", error);
      }
    };

    fetchServerConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleReboot(e) {
    // e.stopPropagation();
    setOpenRebootModal(false);
    rebootServer(addToast);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenSetting(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const { dataVersions = [] } = fetchListVersion(versionsList) || {};

  const lastVersion = dataVersions?.[0];

  async function handleDiscard() {
    setOpenDiscardModal(false);
    navigate(navigateTo);
    await handleClickedItem(
      addToast,
      store,
      {},
      lastVersion?.filename,
      false,
      true
    );
  }

  return (
    <React.Fragment>
      <div className="navbar-container">
        <div className="navbar-left">
          <Icon
            className="logo-plateform"
            icon="logo-filled"
            color={themeColors[themeMode].SupportButtonBg}
            size={43}
            onClick={() => navigate("/monitor")}
          />
        </div>
        {isLoggedin && (
          <div className="navbar-middle">
            <CustomButton
              {...getButtonProps({
                iconName: "monitor",

                navigateTo: "/monitor",
                draftPresence,
                location,
                themeColors,
                themeMode,
                navigate,
                setNavigateTo,
                setOpenDiscardModal,
                pageName,
              })}
              tooltip={"Monitor"}
              disabled={!completedInstallation}
            />
            <CustomButton
              {...getButtonProps({
                iconName: "installer",
                navigateTo: "/installer",
                draftPresence,
                location,
                themeColors,
                themeMode,
                navigate,
                setNavigateTo,
                setOpenDiscardModal,
                pageName,
              })}
              tooltip={"Installer"}
            />
            <CustomButton
              {...getButtonProps({
                iconName: "app-builder",

                navigateTo: "/appbuilder",
                draftPresence,
                location,
                themeColors,
                themeMode,
                navigate,
                setNavigateTo,
                setOpenDiscardModal,
                pageName,
              })}
              tooltip={"App Builder"}
              iconSize={26}
              disabled={!completedInstallation}
              disabledColor="#B9B8BD"
            />
            <CustomButton
              {...getButtonProps({
                iconName: "icons-manager",
                navigateTo: "/assets",
                draftPresence,
                location,
                themeColors,
                themeMode,
                navigate,
                setNavigateTo,
                setOpenDiscardModal,
                pageName,
              })}
              tooltip={"Graphics Manager"}
              iconSize={21}
              disabled={!completedInstallation}
            />
          </div>
        )}
        {isLoggedin && (
          <div className="navbar-right">
            <div
              className="settings-btn"
              onClick={() => {
                setOpenSetting(!openSetting);
              }}
            >
              <Icon
                icon="gear2"
                size={23}
                color={themeColors[themeMode].text}
              />
            </div>
            {openSetting && (
              <div
                ref={dropdownRef}
                //  onMouseLeave={() => setOpenSetting(false)}
                className="settings-dropdown"
              >
                <div className="settings-item">
                  <div className="TransactionServer">
                    <div className="transactionLabel">Transaction Server</div>
                    <Switcher isActive={isTransactionServer} />
                  </div>
                </div>
                <div
                  className={`settings-item ${
                    maintenanceStatus
                      ? "enableMaintenance"
                      : "disableMaintenance"
                  }`}
                  onClick={() => handleUpdateMaintenance(setMaintenanceStatus)}
                >
                  {`Maintenance ${maintenanceStatus ? "Disabled" : "Enabled"}`}
                </div>
                <div
                  className="settings-item"
                  onClick={() => {
                    setOpenRebootModal(true);
                  }}
                >
                  Reboot
                </div>
                <div className="settings-item">
                  <div>{`${themeMode === "dark" ? "Dark" : "Light"} Mode`}</div>
                  <Switcher
                    toggleSwitch={() => toggleTheme()}
                    isActive={themeMode === "dark" ?? false}
                  />
                </div>
                <div
                  className="settings-item"
                  onClick={() => {
                    Logout(store, navigate);
                    UpdateMaximoLoginStatus(store, false);
                  }}
                >
                  Logout
                </div>
              </div>
            )}
            <Modal
              open={openRebootModal}
              onClose={() => {
                setOpenRebootModal(false);
              }}
              onClickPrimaryAction={handleReboot}
              secondaryAction={true}
              onClickSecondaryAction={() => {
                setOpenRebootModal(false);
              }}
              primaryLabel="Confirm"
              secondaryLabe="Cancel"
              modalTitle="Reboot"
              children={"Do you want to reboot server?"}
              alignment="center"
            />
          </div>
        )}
      </div>

      <Modal
        open={openDiscardModal}
        onClose={() => {
          setOpenDiscardModal(false);
        }}
        onClickPrimaryAction={handleDiscard}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setOpenDiscardModal(false);
        }}
        primaryLabel="Confirm"
        secondaryLabe="Cancel"
        modalTitle="Exit Edit Mode"
      >
        <div className="ModalContent">
          <div className="message">
            Are you sure you want to leave edit mode?
          </div>
          <div className="message">Any unsaved changes will be lost!</div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default NavBar;
