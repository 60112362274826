import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import SidebarBtn from "../SidebarBtn";
import { formatFileSize } from "../services/formatFileSize.js";
import renameIcons from "../../../../services/renameIcon";
// import { useToasts } from "react-toast-notifications";
import Icon from "../../../../../../components/CustomIcon/Icon";
import Tooltip from "@mui/material/Tooltip";
import useToasts from "../../../../../../components/CustomToast/useToasts.js";
import { handleRenameIconSubmit } from "../../../../services/index.js";
import fetchSingleImage from "../services/fetchSingleImage.js";

function OneSideBar({
  iconsListing = [],
  iconsSelected = [],
  selectedIcon,
  setIconsListing = () => {},
  setIconsSelected = () => {},
  onDeleteIcon,
  isRenameActive,
  setIsRenameActive,
  currentIcon,
  OnCopy,
  setCurrentIcon,
}) {
  const [renameInputValue, setRenameInputValue] = useState("");
  const { addToast } = useToasts();
  const renameInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [iconNameWithoutExtension, setIconNameWithoutExtension] = useState("");

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    fetchSingleImage(iconsSelected?.[0]).then((url) => {
      setImageSrc(url);
    });

    // Clean up the object URL to avoid memory leaks
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [iconsSelected]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        renameInputRef.current &&
        !renameInputRef.current.contains(event.target)
      ) {
        handleRename();
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [renameInputRef, handleRename]);

  useEffect(() => {
    if (currentIcon) {
      const iconNameWithoutExtension = currentIcon.iconName
        .split(".")
        .slice(0, -1)
        .join(".");
      setRenameInputValue(iconNameWithoutExtension);
      setIconNameWithoutExtension(iconNameWithoutExtension);
    }
  }, [currentIcon]);

  function handleRename() {
    handleRenameIconSubmit(
      renameInputValue,
      setRenameInputValue,
      currentIcon,
      iconsListing,
      setIconsListing,
      setIsRenameActive,
      setIconsSelected,
      addToast,
      renameIcons
    );
  }

  function handleCopyIconName(e) {
    OnCopy(e, iconsSelected[0]?.iconName);
    setOpen(true);
    setTimeout(() => setOpen(false), 1000);
  }

  return (
    <div className="icons-sidebar">
      <div className="sidebar-details">
        <div className="preview">
          <div className="iconPreview">
            <img src={imageSrc} alt={selectedIcon?.iconName} />
          </div>
          <div className="namePreview">
            {isRenameActive ? (
              <div className="input-container" ref={renameInputRef}>
                <input
                  className="NameInput"
                  type="text"
                  value={renameInputValue}
                  onChange={(e) => setRenameInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleRename();
                    }
                  }}
                />
                <Icon
                  size={30}
                  className="closeButton"
                  icon="close1"
                  onClick={() => {
                    setIsRenameActive(false);
                    setRenameInputValue(iconNameWithoutExtension);
                  }}
                />
              </div>
            ) : (
              <div className="IconNameBar">
                <p>{iconsSelected[0]?.iconName}</p>
                <div className="icons">
                  <Tooltip
                    title="Copied"
                    PopperProps={{
                      disablePortal: true,
                      className: "custom-tooltip",
                    }}
                    onClose={() => setOpen(false)}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                  >
                    <Icon
                      icon="Edit3"
                      className="renameIcon"
                      size={22}
                      onClick={() => {
                        setCurrentIcon(iconsSelected[0]);
                        setIsRenameActive(true);
                      }}
                    ></Icon>
                    <Icon
                      icon="Copy3"
                      className="Copyicon"
                      size={22}
                      onClick={(e) =>
                        handleCopyIconName(e, iconsSelected[0]?.iconName)
                      }
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="icons-dtl">
          <div className="dtl-headline">Details</div>
          <div className="dtl-list">
            {iconsSelected ? (
              iconsSelected?.map((icon, index) => (
                <React.Fragment key={index}>
                  <div className="dtl-item">
                    <p>Width</p>
                    <p id="dtl-item-value">{`${icon?.width} pixels`}</p>
                  </div>
                  <div className="dtl-item">
                    <p>Height</p>
                    <p id="dtl-item-value">{`${icon?.height} pixels`}</p>
                  </div>
                  <div className="dtl-item">
                    <p>Size</p>
                    <p id="dtl-item-value">{formatFileSize(icon?.size)}</p>
                  </div>
                  <div className="dtl-item">
                    <p>Extension</p>
                    <p id="dtl-item-value">PNG File</p>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <p>No details available</p>
            )}
          </div>
          <SidebarBtn
            iconsSelected={iconsSelected}
            iconsListing={iconsListing}
            setIconsListing={setIconsListing}
            setIconsSelected={setIconsSelected}
            onDeleteIcon={onDeleteIcon}
          />
        </div>
      </div>
    </div>
  );
}

export default OneSideBar;
