import React, { useEffect, useState } from "react";

import themeColors from "../../../../../theme/themeColors";
import CustomButton from "../../../../../components/CustomButton";
import Icon from "../../../../../components/CustomIcon/Icon";
import CustomModal from "../../../../../components/CustomModal";
import { changeMode } from "../services/getMode";
import { useDispatch } from "react-redux";
import { parseQuery } from "./search-helpers/parseQuery";
import { matchCondition } from "./search-helpers/matchCondition";
import exportRequestsPairs from "./export-import/exportRequests";
import importRequestsPairs from "./export-import/importRequets";
////////

const RESET = "interceptor/resetRequestResponsePair";

const Modes = {
  disableInterceptor: { disableInterceptor: true },
  rabbitmq: { rabbitmq: true },
  justSocket: { justSocket: true },
};

const RequestsHeader = ({
  mode,
  setMode,
  setRequests,
  startSocket,
  stopSocket,
  setInterceptorIsPaused,
  interceptorIsPaused,
  requestsData,
  setSelectedRequest,
  searchedRequestsPairs,
  setSearchedRequestsPairs,
  isSearching,
  setIsSearching,
  selectedRequestsToExport,
}) => {
  const numberOfRequests = requestsData?.filter((req) => req?.request)?.length;
  const numberOfSearchedRequests = searchedRequestsPairs?.filter(
    (req) => req?.request
  )?.length;

  const dispatch = useDispatch();
  const resetPairs = () => {
    dispatch({
      type: RESET,
    });
  };
  const [clearReq, setClearReq] = useState(false);
  const [isChanginMode, setIsChangingMode] = useState(false);
  const [confirmChangeMode, setConfirmChangeMode] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);
  const handleClearAll = () => {
    setSelectedRequest(null);
    resetPairs();
    setRequests([]);
    setSearchedRequestsPairs([]);
    setIsSearching(false);
  };

  const [searchInput, setSearchInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isSearchingCondition, setIsSearchingCondition] = useState(false);

  const [showInstructions, setShowInstructions] = useState(false);
  const handleModeChange = async (modeToChange) => {
    const mode = await changeMode(modeToChange);
    setMode(mode);
  };

  const searchedRequests = (e) => {
    e.preventDefault();
    setIsSearching(true);
    const query = searchInput;

    setSearchInput(query);

    if (query === "" && tags?.length === 0) {
      setIsSearching(false);
      setSearchedRequestsPairs([]);
      return;
    }
    let conditions = [];

    if (query?.length > 0) {
      conditions = parseQuery(query);
    }

    if (tags?.length > 0) {
      const tagConditions = parseQuery(tags?.join(" "));
      conditions = [...conditions, ...tagConditions];
    }

    if (conditions?.length > 0 || isSearchingCondition || tags?.length > 0) {
      setIsSearchingCondition(true);

      let searchResults;

      searchResults = requestsData?.filter((req) => {
        return conditions?.every((condition) => matchCondition(req, condition));
      });

      setSearchedRequestsPairs(searchResults);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      searchedRequests(e);
    } else if (e.key === " ") {
      e.preventDefault();
      const newTag = searchInput?.trim();
      if (newTag && !tags?.includes(newTag)) {
        setTags([...tags, newTag]);
        setSearchInput("");
      }
    }
  };
  const handleTagRemove = (tagToRemove) => {
    const updatedTags = tags?.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  const importRequests = async (file) => {
    try {
      const result = await importRequestsPairs(file);
      if (result) {
        setRequests(result);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (!searchInput && tags?.length === 0) {
      setIsSearchingCondition(false);
      setIsSearching(false);
      setSearchedRequestsPairs([]);
    }
  }, [searchInput, tags, setIsSearching, setSearchedRequestsPairs]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event?.key === "Delete" || event?.key === "Backspace") &&
        searchInput?.length === 0 &&
        tags?.length > 0
      ) {
        setTags((prevTags) => prevTags?.slice(0, -1));
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchInput, tags]);

  return (
    <div className="request-header-container">
      {/* modal */}
      <CustomModal
        open={clearReq}
        onClose={() => {
          setClearReq(false);
        }}
        onClickPrimaryAction={handleClearAll}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setClearReq(false);
        }}
        primaryLabel="Confirm"
        secondaryLabe="Cancel"
        modalTitle="Clear All Requests"
        children={"Are you sure you want to clear all requests?"}
        alignment="center"
      >
        <p>Are you sure you want to clear all requests?</p>
      </CustomModal>
      <CustomModal
        open={confirmChangeMode}
        onClose={() => setConfirmChangeMode(false)}
        onClickPrimaryAction={() => {
          handleModeChange(selectedMode);
          setConfirmChangeMode(false);
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => setConfirmChangeMode(false)}
        primaryLabel="Change Mode"
        secondaryLabe="Cancel"
        modalTitle="Change Interceptor Mode"
        alignment="center"
      >
        <p>You’re about to switch Mode. Would you like to proceed?</p>
      </CustomModal>
      <div className="request-header">
        <h2
          style={{
            color: themeColors.light.requestsNb,
            fontSize: "24px",
          }}
        >
          {numberOfRequests}{" "}
          <span
            style={{
              fontSize: "20px",
              fontWeight: 400,
            }}
          >
            Requests
          </span>
        </h2>

        <div className="buttons-requests">
          <CustomButton
            iconName={interceptorIsPaused ? "Resume" : "Pause"}
            iconColor={themeColors.light.interceptorBtn}
            iconSize={25}
            tooltip={
              interceptorIsPaused ? "Resume intercepting" : "Pause intercepting"
            }
            onClick={() => {
              interceptorIsPaused ? startSocket() : stopSocket();
              setInterceptorIsPaused(!interceptorIsPaused);
            }}
          />
          {/* vertical seperator */}
          <div
            style={{
              borderLeft: "2px solid #D7D7D7",
              height: "21px",
              margin: "0px 10px",
              borderRadius: "50px",
            }}
          ></div>
          <CustomButton
            iconName="export3"
            disabled={selectedRequestsToExport?.length === 0}
            iconColor={
              selectedRequestsToExport?.length === 0
                ? ""
                : themeColors.light.interceptorBtn
            }
            iconSize={25}
            tooltip="Export Requests"
            onClick={() => exportRequestsPairs(selectedRequestsToExport)}
          />
          {/* vertical seperator */}
          <div
            style={{
              borderLeft: "2px solid #D7D7D7",
              height: "21px",
              margin: "0px 10px",
              borderRadius: "50px",
            }}
          ></div>
          {/* import */}
          <CustomButton
            iconName="export2"
            iconColor="#4C36A5"
            iconSize={25}
            tooltip="Import Requests"
            onClick={() => {
              const input = document.createElement("input");
              input.type = "file";
              input.accept = ".json";
              input.onchange = (e) => {
                if (e.target.files && e.target.files[0]) {
                  importRequests(e.target.files[0]);
                }
              };
              input.click();
            }}
          />
          <div
            style={{
              borderLeft: "2px solid #D7D7D7",
              height: "21px",
              margin: "0px 10px",
              borderRadius: "50px",
            }}
          ></div>
          <CustomButton
            iconName="delete_solid"
            iconColor={themeColors.light.interceptorBtn}
            iconSize={25}
            tooltip="Clear All Requests"
            onClick={() => setClearReq(true)}
          />
          <div
            style={{
              borderLeft: "2px solid #D7D7D7",
              height: "21px",
              margin: "0px 10px",
              borderRadius: "50px",
            }}
          ></div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              position: "relative",
            }}
          >
            {isChanginMode && (
              <div
                style={{
                  position: "absolute",
                  top: "35px",
                  right: "0",
                  backgroundColor: "#fff",
                  border: "1px solid #EEEEEE",
                  borderRadius: "2px",
                  zIndex: 9999,
                  display: "flex",

                  flexDirection: "column",
                }}
              >
                <div
                  className="selection-mode"
                  onClick={() => {
                    setSelectedMode(Modes.justSocket);
                    setConfirmChangeMode(true);
                    setIsChangingMode(false);
                  }}
                >
                  <div>Realtime</div>
                  {/* checked icon for if it s selected */}
                  {mode?.httpInterceptor ? (
                    !mode?.rabbitmq && mode?.justSocket ? (
                      <Icon
                        icon="check"
                        size={20}
                        style={{
                          color: "#16A34A",
                        }}
                      />
                    ) : null
                  ) : null}
                </div>
                <div
                  className="selection-mode"
                  onClick={() => {
                    setSelectedMode(Modes.rabbitmq);
                    setConfirmChangeMode(true);
                    setIsChangingMode(false);
                  }}
                >
                  <div>Persist</div>
                  {/* checked icon for if it s selected */}
                  {mode?.httpInterceptor ? (
                    mode?.rabbitmq ? (
                      <Icon
                        icon="check"
                        size={20}
                        style={{
                          color: "#16A34A",
                        }}
                      />
                    ) : null
                  ) : null}
                </div>

                <div
                  className="selection-mode"
                  onClick={() => {
                    setSelectedMode(Modes.disableInterceptor);
                    setConfirmChangeMode(true);
                    setIsChangingMode(false);
                  }}
                >
                  <div
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Disabled
                  </div>
                  {/* checked icon for if it s selected */}
                  {!mode?.httpInterceptor ? (
                    <Icon
                      icon="check"
                      size={20}
                      style={{
                        color: "#16A34A",
                      }}
                    />
                  ) : null}
                </div>
              </div>
            )}
            <div
              style={{
                //disabled border red
                border: !mode?.httpInterceptor
                  ? "1px solid #FF0000"
                  : "1px solid #16A34A",
                color: !mode?.httpInterceptor ? "#FF0000" : "#16A34A",
                backgroundColor: !mode?.httpInterceptor ? "#FFD6D8" : "#DCF1E4",
                borderRadius: "72px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {mode?.httpInterceptor ? "On" : "Off"}
            </div>
            {/* mode */}

            <div
              style={{
                color: "#39354A",
                fontSize: "16px",
                fontWeight: 400,
              }}
            >
              {mode?.httpInterceptor
                ? mode?.rabbitmq
                  ? "Persist"
                  : "Realtime"
                : "Disabled"}
            </div>
            {/* menu-dots-vertical */}
            <CustomButton
              onClick={() => {
                setIsChangingMode(!isChanginMode);
              }}
              iconName="menu-dots-vertical"
              iconColor="#4C36A5"
              iconSize={20}
              tooltip="Change Mode"
            />
            {/* the actuial mode */}
          </div>
          {/* )} */}
        </div>
      </div>
      {/* search */}
      <form className="search-requests" onSubmit={searchedRequests}>
        <div className="tags">
          {tags?.map((tag, index) => (
            <span key={index} className="tag">
              {tag}
              <Icon
                icon="deleteX"
                size={13}
                onClick={() => handleTagRemove(tag)}
                style={{
                  cursor: "pointer",
                }}
              />
            </span>
          ))}
        </div>
        <input
          type="text"
          placeholder="Search"
          className="search-input"
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          // onChange={searchedRequests}
          // onClick={searchedRequests}
          onKeyDown={handleKeyDown}
        />
        <Icon
          onClick={searchedRequests}
          icon="search"
          size={22}
          className="search-icon"
          style={{
            color:
              searchInput || tags?.length > 0
                ? themeColors.light.interceptorBtn
                : "#989898",
            cursor: "pointer",
          }}
        />
        <div className="searched-requests">
          {/* Instructions for using search */}
          <button
            className="info-icon"
            onClick={() => {
              setShowInstructions(!showInstructions);
            }}
          >
            i
          </button>
          {showInstructions && (
            <div className="search-instructions">
              <h3>How to Use Interceptor Search</h3>
              <ul>
                <li>
                  <strong>Basic Search:</strong> Enter any keyword to search
                  through requests. You can search for words like{" "}
                  <code>GET</code>, <code>200</code>or <code>sigoption</code>.
                  The search will include results where the keyword appears in
                  the <code>host</code>, <code>status</code>, <code>path</code>,
                  or <code>method</code>.
                </li>
                <li>
                  <strong>Advanced Search:</strong> Use operators to refine your
                  search. For example:
                  <ul>
                    <li>
                      <code>method==POST</code> to find requests with the POST
                      method.
                    </li>
                    <li>
                      <code>host==example.com</code> to find requests with the
                      specified host.
                    </li>
                    <li>
                      <code>status==200</code> to find requests with a specific
                      status code.
                    </li>
                    <li>
                      <code>path==/api/resource</code> to find requests with a
                      specific path.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Combining Searches:</strong> You can combine basic and
                  advanced search queries. For example, to find requests with
                  the POST method that include the word <code>sig</code>, you
                  can use <code>method==POST</code> and then search for{" "}
                  <code>sig</code> within those POST requests.
                </li>
                {/*  a note to indicate available operators */}
                <li>
                  <strong>Available Operators:</strong> <code>==</code>,
                  <code>!=</code>, <code>&gt;</code>,<code>&gt;=</code>,
                  <code>&lt;</code>, <code>&lt;=</code> .
                </li>
              </ul>
            </div>
          )}
          <div
            style={{
              borderLeft: "2px solid #D7D7D7",
              alignSelf: "center",
              height: "20px",
              margin: "0 10px",
            }}
          ></div>
          {isSearching && (
            <span className="requests-result">{numberOfSearchedRequests}</span>
          )}
          {isSearching && <span className="requests-result">/</span>}
          <span className="requests-result">{numberOfRequests}</span>
          <span className="requests-span">Requests</span>
        </div>
      </form>

      {/* export import */}
      {/* <CustomButton
        onClick={() => exportRequestsPairs(requestsData)}
        label="Export Requests"
        iconName="export"
        iconSize={20}
      />
      <input
        type="file"
        accept=".json"
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            importRequestsPairs(e.target.files[0]);
          }
        }}
      /> */}
    </div>
  );
};

export default React.memo(RequestsHeader);
